import React, { useEffect, useState } from 'react'
import './CarDetail.css'
import { Container, Row, Col, Accordion, Modal, Carousel } from 'react-bootstrap'
import IconClose from '../../assets/images/icon-close.png'
import { Link } from "react-router-dom";
import IconShare from '../../assets/images/icon-share.png'
import IconFacebookCircle from '../../assets/images/icon-facebook-circle.png'
import IconWhatsappCircle from '../../assets/images/icon-whatsapp-circle1.png'
import IconInstagramCircle from '../../assets/images/icon-instagram-circle.png'
import ArrowIcon from '../../assets/images/icon-arrow.png'
import { FormField } from '../../components/FormField/FormField'
import { FormTextArea } from '../../components/FormTextArea/FormTextArea'
import Slider from "react-slick";
import { postEventGA } from '../../helper/helper'
import IconArrowRight from '../../assets/images/right-arrow.png'
import PrismaZoom from 'react-prismazoom'

import { getCurrencyFormat, getEmbedYoutube, normalizeErrorMessage } from '../../helper/helper'

import { useParams } from 'react-router-dom';
import { APIGET, APIPOST } from "../../helper/api";
import { ACCESS_TOKEN, FACEBOOK_URL, CAR_URL, BASE_URL, INSTAGRAM_URL, WHATSAPP_URL, CAR_ENQUIRY_DETAIL_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet';

export default function CarDetail() {
    const [carForm, setCarForm] = useState({
        form: {
            full_name: {
                value: 
                localStorage.getItem("first_name") ?
                localStorage.getItem("first_name")+" "+localStorage.getItem("last_name")
                :
                "",
            },
            email: {
                value: '',
            },
            mobile_number: {
                value: '',
            },
            description: {
                value: '',
            },
            manufacturer: {
                value: '-',
            },
            serie: {
                value: '-',
            },
            token: {
                value: ACCESS_TOKEN ? ACCESS_TOKEN : ""
            },
        }
    })

    const [carPictures, setCarPictures] = useState([])
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")
    const [show, setShow] = useState(false)
    const [showLeasing, setShowLeasing] = useState(false)
    const [showShare, setShowShare] = useState(false)
    const [showImage, setShowImage] = useState(false)

    const clickShowLeasing = () => {
        postEventGA("car/detail/leasing", "click", cars.serie)
        setShowLeasing(!showLeasing)
    }

    const clickShowShare = () => {
        postEventGA("car/detail/share", "click", cars.serie)
        setShowShare(!showShare)
    }

    const clickShowImage = () => {
        postEventGA("car/detail/see-all-image", "click", cars.serie)
        setShowImage(!showImage)
    }

    const setHandleValueChange = (event) => {
        let copyForm = carForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setCarForm(copyForm)
    }

    const params = useParams()
    const [cars, setCar] = useState([])
    let param = {
        slug: params.id,
        with_pictures: true,
        token: ACCESS_TOKEN,
    }

    const handleSubmit = async () => {
        postEventGA("car/detail/inquiry", "click", cars.serie)
        const formCopy = carForm.form
        let formToSubmit = {}
        for (let key in formCopy) {
            formToSubmit[key] = formCopy[key].value
        }
        formToSubmit['manufacturer'] = cars.manufacturer
        formToSubmit['serie'] = cars.serie
        let res = await APIPOST(CAR_ENQUIRY_DETAIL_URL, formToSubmit)
        if (res.detail) {
            setGlobalErrorMessage(normalizeErrorMessage(res.detail))
        } else {
            setShow(true)
        }
    }
    
    useEffect(() => {
        const fetchData = async () => {
            const res = await APIGET(CAR_URL, param)
            setCar(res[0])
            setCarPictures(res[0].pictures)
        }
        fetchData()
    }, [])

    const [index, setIndex] = useState(13);

    const handleSelect = (selectedIndex) => {
        if(selectedIndex.target){
            setIndex(parseInt(selectedIndex.target.attributes.idx.value))
            console.log(parseInt(selectedIndex.target.attributes.idx.value))
        }
    };
    // const sliderNextPrev = (count) => {
    //     if(count === 1){
    //         if(carPictures.length < index){
    //             setIndex(index+1)
    //         }
    //     }else{
    //         if(index > 1){
    //             setIndex(index-1)
    //         }
    //     }
    // };

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Cars Detail</title>
        </Helmet>
        <Container fluid className="car-detail-mobile">
            <Container>
                <Row>
                    <Col lg={12} xs={12} className="car-detail-back">
                        <p>
                            <Link to='/login'>
                                <img src={ArrowIcon} alt="." className="icon-arrow"/>
                                CARS
                            </Link>
                        </p>
                    </Col>
                    <Col lg={9} xs={12} className="car-detail-title">
                        <p>{cars.serie}</p>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="car-detail-image-container justify-content-center">
                <Row>
                    <Col lg={12} xs={12} className="car-detail-image">
                        <img src={BASE_URL+cars.picture} alt="car" onClick={clickShowImage}/>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} xs={12} className="car-detail-button-container d-flex justify-content-between pb-3">
                    <button className="w-md-50 car-detail-button-see-all-images button-rectangle-small p-2"
                    onClick={clickShowImage}>
                        <p>SEE ALL IMAGES</p>
                    </button>
                    <button className="w-md-50 car-detail-button-leasing-simulator button-rectangle-small p-2"
                    onClick={clickShowLeasing}>
                        <p>LEASING SIMULATOR</p>
                    </button>
                    </Col>
                    <Col lg={12} xs={12}>                    
                        <img className="car-detail-media-second" src={BASE_URL+cars.picture} alt="car" onClick={clickShowImage}/>
                    </Col>
                </Row>
                <Row className="car-detail-price-row">
                    <Col lg={4} xs={9} className="car-detail-price">
                        <p>PRICE</p>
                        <p>
                            { 
                                getCurrencyFormat(cars.price)
                            }
                        </p>
                    </Col>
                    <Col lg={0} xs={3} className="car-detail-share">
                        <img src={IconShare} alt="share"
                            onClick={clickShowShare}
                        />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="car-detail-form justify-content-lg-center">
                    <Col lg={12} xs={12} className="special-request-title">
                        <h1>Request Form</h1>
                    </Col>
                    <FormField class_extend="col-lg-5 col-xs-12" nameField="full_name" val={carForm} setHandleValueChange={setHandleValueChange} field_title="FULLNAME" field_type="text" field_place_holder="Type full name"/>
                    <FormField class_extend="col-lg-5 col-xs-12" nameField="email" val={carForm} setHandleValueChange={setHandleValueChange} field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
                    <FormField class_extend="col-lg-5 col-xs-12" nameField="mobile_number" val={carForm} setHandleValueChange={setHandleValueChange} field_title="MOBILE NUMBER" field_type="text" field_place_holder="Type mobile number"/>
                    <FormTextArea class_extend="col-lg-5 col-xs-12" nameField="description" val={carForm} setHandleValueChange={setHandleValueChange} field_title="MESSAGE" field_type="textarea" field_row="5" field_place_holder="Type message"/>
                    <Col lg={12} xs={12}>
                        <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                            {globalErrorMessage ? globalErrorMessage : "success"}
                        </p>
                    </Col>
                    <Col lg={3} xs={12} className="justify-content-center d-flex">
                        <button
                        className={"special-request-button button-rectangle"}
                        onClick={handleSubmit}
                        >
                            <p>SUBMIT</p>
                        </button>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} xs={12} className="car-detail-title">
                        <h2>Specifications</h2>
                    </Col>
                    <Row className="border-bottom mb-3">
                        <Col lg={6} xs={6}>
                            <p>YEAR</p>
                        </Col>
                        <Col lg={6} xs={6}>
                            <p className="d-flex">{cars.year ? cars.year : "-"}</p>
                        </Col>
                    </Row>
                    <Row className="border-bottom mb-3">
                        <Col lg={6} xs={6}>
                            <p>KM</p>
                        </Col>
                        <Col lg={6} xs={6}>
                            <p className="d-flex">{cars.mileage ? cars.mileage.toLocaleString("en-US") : "-"}</p>
                        </Col>
                    </Row>
                    <Row className="border-bottom mb-3">
                        <Col lg={6} xs={6}>
                            <p>ENGINE</p>
                        </Col>
                        <Col lg={6} xs={6}>
                            <p className="d-flex">{cars.engine ? cars.engine : "-"}</p>
                        </Col>
                    </Row>
                    <Row className="border-bottom mb-3">
                        <Col lg={6} xs={6}>
                            <p>COLOR</p>
                        </Col>
                        <Col lg={6} xs={6}>
                            <p className="d-flex">{cars.exterior_colour ? cars.exterior_colour : "-"}</p>
                        </Col>
                    </Row>
                </Row>
                <Row className="car-detail-specification-row">
                    <Col lg={12} xs={12} className="car-detail-title">
                        <h2>Descriptions</h2>
                    </Col>
                    <Col lg={12} xs={12} className="car-detail-title" dangerouslySetInnerHTML={{__html: cars.description}}>
                    </Col>
                </Row>
            </Container>
        </Container>

        <Container className="car-detail-desktop">
            <Row>
                <Col lg={12} className="car-detail-back">
                    <p>
                        <Link to='/login'>
                            <img src={ArrowIcon} alt="." className="icon-arrow"/>
                            CARS
                        </Link>
                    </p>
                </Col>
                <Col lg={5}>
                    <Row>
                        <Col lg={12} className="car-detail-title">
                            <p>{ cars.serie }</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className="car-detail-price">
                            <p>PRICE</p>
                            <p>
                                { 
                                    getCurrencyFormat(cars.price)
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className="car-detail-accordion-col">
                            <Accordion flush className="car-detail-accordion">
                                <Accordion.Item eventKey="2" className="car-detail-accordion-card">
                                <Accordion.Header ><p>LEASING SIMULATOR</p></Accordion.Header>
                                <Accordion.Body className="car-detail-accordion-body">
                                    <div className={cars.leasing ? "leasing-image-container" : "d-none"}>
                                        <img src={cars.leasing ? BASE_URL+cars.leasing : ""} alt="."/>
                                    </div>
                                    <p className="car-detail-accordion-card-p">
                                        *This is simulation price, for more information please contact us
                                    </p>
                                </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className="car-detail-form justify-content-lg-center">
                        <Col lg={12} xs={12} className="car-detail-form-title">
                            <h2>Request Form</h2>
                        </Col>
                        <FormField class_extend="col-lg-12 car-detail-field" nameField="full_name" val={carForm} setHandleValueChange={setHandleValueChange} field_title="FULLNAME" field_type="text" field_place_holder="Type full name"/>
                        <FormField class_extend="col-lg-12 car-detail-field" nameField="email" val={carForm} setHandleValueChange={setHandleValueChange} field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
                        <FormField class_extend="col-lg-12 car-detail-field" nameField="mobile_number" val={carForm} setHandleValueChange={setHandleValueChange} field_title="MOBILE NUMBER" field_type="text" field_place_holder="Type mobile number"/>
                        <FormTextArea class_extend="col-lg-12 car-detail-field" nameField="description" val={carForm} setHandleValueChange={setHandleValueChange} field_title="MESSAGE" field_type="textarea" field_row="5" field_place_holder="Type message"/>
                        
                        <Col lg={12} xs={12}>
                            <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                                {globalErrorMessage ? globalErrorMessage : "success"}
                            </p>
                        </Col>
                        <Col lg={12} className="justify-content-center d-flex">
                            <button
                            className={"special-request-button button-rectangle w-60"}
                            onClick={handleSubmit}
                            >
                                <p>SUBMIT</p>
                            </button>
                        </Col>
                    </Row>
                </Col>
                <Col lg={7} className="car-detail-desktop-right">
                    <Row className="justify-content-flex-end">
                        <Col lg={12} className="car-detail-media-social">
                            <p>CALL US ON</p>
                            <div className="car-detail-button-whatsapp">
                                <a rel="noopener noreferrer" href={WHATSAPP_URL} target="_blank">
                                    <img src={IconWhatsappCircle} alt="whatsapp"/>
                                    <p>
                                    +6281386595922
                                    </p>
                                </a>
                            </div>
                            <p>SHARE</p>
                            <div className="car-detail-media-social-icon-collection">           
                                <a rel="noopener noreferrer" href={FACEBOOK_URL} target="_blank">
                                    <img src={IconFacebookCircle} alt="f"/>
                                </a>
                                <a rel="noopener noreferrer" href={INSTAGRAM_URL} target="_blank">
                                    <img src={IconInstagramCircle} alt="i"/>
                                </a>
                                <a rel="noopener noreferrer" href={WHATSAPP_URL} target="_blank">
                                    <img src={IconWhatsappCircle} alt="w"/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row className="car-detail-row">
                        <Col lg={12} className="car-detail-image">
                            <img src={BASE_URL+cars.picture} alt="car" onClick={clickShowImage}/>
                        </Col>
                    </Row>
                    <Row className="car-detail-row car-detail-image-row">
                        {   
                            carPictures ?
                            carPictures.map((car,index) => {
                                if(index === 3){
                                    return (
                                        <Col lg={3} className="car-detail-image-collection car-detail-image-collection-last" key={index}>
                                            <img src={BASE_URL+car.picture} alt="car"/>
                                            <div className="car-detail-see-all" onClick={clickShowImage}>
                                                <p>SEE ALL IMAGES</p>
                                            </div>
                                        </Col>
                                    )
                                }
                                else if(index < 3){
                                    return(
                                        <Col lg={3} className="car-detail-image-collection" key={index}>
                                            <img src={BASE_URL+car.picture} alt="car"/>
                                        </Col>
                                    )
                                }
                                return ""
                            })
                            :
                            ""
                        }
                    </Row>
                    <Row className="car-detail-row">
                        <Col lg={12} className={cars.video_url === "" ? "car-detail-youtube display-none" : "car-detail-youtube"}>
                            <iframe
                                id="iframe-detail-car"
                                src={"https://www.youtube.com/embed/"+getEmbedYoutube(cars.video_url)}
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title={cars.serie}
                            />
                        </Col>
                    </Row>
                    <Row className="car-detail-specification-row">
                        <Col lg={12} xs={12} className="car-detail-title">
                            <h2>Specifications</h2>
                            <Row className="border-bottom mb-3">
                                <Col lg={6} xs={6}>
                                    <p>YEAR</p>
                                </Col>
                                <Col lg={6} xs={6}>
                                    <p className="d-flex">{cars.year ? cars.year : "-"}</p>
                                </Col>
                            </Row>
                            <Row className="border-bottom mb-3">
                                <Col lg={6} xs={6}>
                                    <p>KM</p>
                                </Col>
                                <Col lg={6} xs={6}>
                                    <p className="d-flex">{cars.mileage ? cars.mileage.toLocaleString("en-US") : "-"}</p>
                                </Col>
                            </Row>
                            <Row className="border-bottom mb-3">
                                <Col lg={6} xs={6}>
                                    <p>ENGINE</p>
                                </Col>
                                <Col lg={6} xs={6}>
                                    <p className="d-flex">{cars.engine ? cars.engine : "-"}</p>
                                </Col>
                            </Row>
                            <Row className="border-bottom mb-3">
                                <Col lg={6} xs={6}>
                                    <p>COLOR</p>
                                </Col>
                                <Col lg={6} xs={6}>
                                    <p className="d-flex">{cars.exterior_colour ? cars.exterior_colour : "-"}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="car-detail-specification-row mb-3">
                        <Col lg={12} xs={12} className="car-detail-title">
                            <h2>Descriptions</h2>
                        </Col>
                        <Col lg={12} xs={12} className="car-detail-description" dangerouslySetInnerHTML={{__html: cars.description}}>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

        <Modal id="modal-close-info" show={show} centered>
            <Modal.Header>
            <Modal.Title>
                <p>Info</p>
                <img src={IconClose} 
                alt="x"
                onClick={() => window.location.reload(false)}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p  className='text-center'>
                Thank you for submit,<br />
                Our sales will contact you soon
                </p>
            </Modal.Body>
        </Modal>

        <Modal 
            fullscreen={true}
            show={showImage}
            id="modal-see-all-image">
            <Modal.Header className="my-garage-modal">
                <div className="w-100">
                    <p className="till-font d-sm-none fst-italic m-0">TILT YOUR PHONE</p>
                </div>
                <Modal.Title className="d-flex justify-content-end">
                    <img src={IconClose} 
                    alt="x"
                    onClick={clickShowImage}
                    className="tda-see-all-images"/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={12} xs={12} className="text-center car-detail-carousel-container p-0 p-lg-2 position-relative">
                            <div className='position-absolute container-slider-arrow-left'>
                                <div
                                className="slider-car-detail-page-prev slider-car-detail-page-arrow"
                                    onClick={() => {
                                        if(index > 0){
                                            setIndex(index-1)
                                        }
                                    }}
                                >
                                <img src={IconArrowRight} alt="a" 
                                    
                                />
                                </div>
                            </div>
                            <div className='position-absolute container-slider-arrow-right'>
                                <div
                                className="slider-car-detail-page-next slider-car-detail-page-arrow"
                                    onClick={() => {
                                        if(carPictures.length > index){
                                            setIndex(index+1)
                                        }
                                    }}
                                >
                                    <img src={IconArrowRight} alt="a" 
                                    
                                    />
                                </div>
                            </div>
                            <Carousel fade onSelect={handleSelect} activeIndex={index} slide={false} indicators={false} controls={false}>
                                <Carousel.Item>
                                    <PrismaZoom className="d-flex justify-content-center slider-detail-car">
                                        <img src={BASE_URL+cars.picture} alt="slider"/>
                                    </PrismaZoom>
                                </Carousel.Item>
                                {
                                    carPictures.map((car,index) => {
                                        return(
                                            <Carousel.Item key={index}>
                                                <PrismaZoom className='d-flex justify-content-center slider-detail-car'>
                                                    <img src={BASE_URL+car.picture} alt="slider"/>
                                                </PrismaZoom>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                            <div className='d-flex justify-content-start overflow-auto mb-3'>
                                <div 
                                    className='slider-car-detail-navigation slider-car-first mt-2'
                                    value={0}
                                >
                                    <img src={BASE_URL+cars.picture} alt="slider"
                                                idx={0} 
                                                onClick={handleSelect}/>
                                </div>
                                {
                                    carPictures.map((car,index) => {
                                        return(
                                            <div 
                                                className='slider-car-detail-navigation mt-2 px-md-1'
                                                key={index} 
                                            >
                                                <img src={BASE_URL+car.picture} alt="a"
                                                idx={index+1} 
                                                onClick={handleSelect}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

        <Modal 
            fullscreen={true}
            show={showShare}
            id="modal-share">
            <Modal.Header className="my-garage-modal">
                <Modal.Title className='d-flex justify-content-end'>
                    <img src={IconClose} 
                    alt="x"
                    onClick={clickShowShare}
                    className="tda-see-all-images"
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex align-items-center pb-3 pt-0'>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={12} xs={12} className="text-center car-detail-share-select">
                            <a rel="noopener noreferrer" href={FACEBOOK_URL} target="_blank">
                                <img src={IconFacebookCircle} alt="f"/>
                            </a>
                            <a rel="noopener noreferrer" href={INSTAGRAM_URL} target="_blank">
                                <img src={IconInstagramCircle} alt="i"/>
                            </a>
                            <a rel="noopener noreferrer" href={WHATSAPP_URL} target="_blank">
                                <img src={IconWhatsappCircle} alt="w"/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

        <Modal 
            fullscreen={true}
            show={showLeasing}
            id="modal-see-all-image">
            <Modal.Header className="my-garage-modal">
                <Modal.Title className='d-flex justify-content-end'>
                    <img src={IconClose} 
                    alt="x"
                    onClick={clickShowLeasing}
                    className="tda-see-all-images"
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={12} xs={12} className="text-center">
                            <div className={cars.leasing ? "leasing-image-container" : "d-none"} >
                                <img src={cars.leasing ? BASE_URL+cars.leasing : ""} alt="."/>
                            </div>
                            <p className="car-detail-accordion-card-p">
                                *This is simulation price, for more information please contact us
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
        </>
    );
}
